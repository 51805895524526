// Doing what antd/lib/style/core/index.less does
// but without loading "base" which overwrites global styles
// See https://github.com/ant-design/ant-design/issues/4331#issuecomment-396875383

@import '~antd/lib/style/mixins/index.less';
@import '~antd/lib/style/core/iconfont.less';
@import '~antd/lib/style/core/motion.less';

@import "~antd/lib/grid/style/index.css";
@import "~antd/lib/icon/style/index.css";
@import '~antd/lib/button/style/index.less';
@import '~antd/lib/modal/style/index.less';
@import "~antd/lib/select/style/index.css";
@import "~antd/lib/tree-select/style/index.css";
@import "~antd/lib/cascader/style/index.css";
@import "~antd/lib/switch/style/index.css";
@import '~antd/lib/menu/style/index.less';
@import "~antd/lib/dropdown/style/index.css";
@import "~antd/lib/radio/style/index.css";
@import "~antd/lib/slider/style/index.css";
@import "~antd/lib/tooltip/style/index.css";
@import "~antd/lib/popover/style/index.css";
@import "~antd/lib/time-picker/style/index.css";
@import "~antd/lib/date-picker/style/index.css";
@import "~antd/lib/input/style/index.css";
@import "~antd/lib/input-number/style/index.css";
@import '~antd/lib/checkbox/style/index.less';
@import "~antd/lib/calendar/style/index.css";
@import "~antd/lib/config-provider/style/index.css";
@import "~antd/lib/locale-provider/style/index.css";
@import "~antd/lib/auto-complete/style/index.css";
@import "~antd/lib/empty/style/index.css";

@import "~antd/lib/collapse/style/index.css";
@import "~antd/lib/alert/style/index.css";
@import "~antd/lib/message/style/index.css";

